import { Box, Divider, Typography } from "@mui/material";

export const Header = () => {
  // useEffect(() => {
  //   window.addEventListener("scroll", handleOnScroll);
  //   return () => window.removeEventListener("scroll", handleOnScroll);
  // }, []);

  return (
    <>
      <Box
        // onScroll={handleOnScroll}
        sx={{
          textAlign: "center",
          position: "sticky",
          top: "0",
          zIndex: "2",
          backgroundColor: "white",
          transition: "font-size 2s ease",
        }}
      >
        <Box paddingY="20px">
          <Typography
            sx={{
              color: "black",
              position: "sticky",
              fontSize: "25px",
            }}
            fontFamily={"Rubik"}
          >
            Mau makan apa hari ini?
          </Typography>
        </Box>
        <Divider />
      </Box>
    </>
  );
};
