import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Avatar, Chip, Divider, Grid } from "@mui/material";

import {
  categoryToStr,
  formatPaymentMethod,
  priceFormat,
} from "../helpers/util";
import { ArrowOutward, ChevronRight } from "@mui/icons-material";

const Addons = ({ addon }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={0.3}></Grid>
        <Grid item xs={8.7}>
          <Typography sx={{ fontSize: "12px", color: "#4E4E4E" }}>
            <ChevronRight fontSize="inherit" sx={{ rotate: "135deg" }} />
            {addon.name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            sx={{
              fontSize: "12px",
              color: "text.secondary",
              textAlign: "end",
              marginRight: "30%",
            }}
          >
            {priceFormat(addon.price, true)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

const AccordionDetail = ({ currentIdx, menuCount, menu }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <Typography sx={{ fontSize: "12px", color: "#4E4E4E" }}>
            {menu.name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            sx={{
              fontSize: "12px",
              color: "text.secondary",
              textAlign: "end",
              marginRight: "30%",
            }}
          >
            {priceFormat(menu.price)}
          </Typography>
        </Grid>
      </Grid>
      {menu.addons
        ? menu.addons.map((addon, i) => <Addons addon={addon} key={i} />)
        : null}

      {currentIdx + 1 !== menuCount ? <Divider /> : null}
    </>
  );
};

const AnotherInformation = ({ props }) => {
  return (
    <>
      <Typography fontFamily={"Rubik"} marginTop={"10px"}>
        Info lainnya
      </Typography>
      <Typography
        width={"auto"}
        fontSize={"12px"}
        onClick={() => {
          return !props.mapLocationUrl
            ? ""
            : window.open(props.mapLocationUrl, "_blank", "noreferrer");
        }}
        sx={{
          cursor: "pointer",
          display: "inline-block",
        }}
      >
        Gmaps <ArrowOutward fontSize="12px" />
      </Typography>
    </>
  );
};

const AdditionalCost = ({ props }) => {
  return (
    <>
      <Typography fontFamily={"Rubik"} marginTop={"10px"}>
        Biaya tambahan
      </Typography>
      {props.ppn ? (
        <Typography width={"auto"} fontSize={"12px"} color="#4E4E4E">
          Pajak {props.ppn}
        </Typography>
      ) : null}
      {props.serviceCharge ? (
        <Typography width={"auto"} fontSize={"12px"} color="#4E4E4E">
          Biaya layanan {props.serviceCharge}
        </Typography>
      ) : null}
      {props.admin ? (
        <Typography width={"auto"} fontSize={"12px"} color="#4E4E4E">
          Biaya Admin {props.admin}
        </Typography>
      ) : null}
    </>
  );
};

const PaymentMethod = ({ props }) => {
  return (
    <>
      <Typography fontFamily={"Rubik"} marginTop={"10px"}>
        Metode bayar
      </Typography>
      <Grid container>
        {formatPaymentMethod(props).map((method, idx) => (
          <Grid item display="flex" key={idx}>
            <Typography fontSize={12} color="#4E4E4E">
              {method}
            </Typography>
            {idx + 1 !== props.length ? (
              <Typography
                marginX={"9.6px"}
                fontSize={12}
                color={"text.secondary"}
              >
                •
              </Typography>
            ) : null}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const RatingChip = ({ reviewName, reviewStar, mr = "0px" }) => {
  return (
    <Chip
      size="small"
      avatar={
        <Avatar sx={{ bgcolor: "#2BCDC1" }}>
          <Typography fontSize="12px" color="#393E46">
            {reviewName}
          </Typography>
        </Avatar>
      }
      label={
        <Typography fontSize="12px">★ {reviewStar.toFixed(1)} / 5.0</Typography>
      }
      variant="outlined"
      sx={{ marginRight: mr }}
    />
  );
};

const OurReview = ({ props }) => {
  return (
    <>
      <Typography fontFamily={"Rubik"} marginTop={"10px"}>
        Kata kita
      </Typography>
      <Grid container>
        {props.rating && props.rating.derby ? (
          <RatingChip reviewName="D" reviewStar={props.rating.derby} mr="5px" />
        ) : null}
        {props.rating && props.rating.riska ? (
          <RatingChip reviewName="R" reviewStar={props.rating.riska} />
        ) : null}
      </Grid>
    </>
  );
};

const formatLabel = (text, highlight) => {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <>
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { color: "#007FFF" }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </>
  );
};

export const ControlledAccordions = ({ props, search }) => {
  const noMenu = { name: "Belum ada hidangan" };

  let title = props.title;

  const [expanded, setExpanded] = useState(false);
  const [currentSearch, setCurrentSearch] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (currentSearch !== search) {
    setExpanded(false);
    setCurrentSearch(search);
  }

  if (search) {
    title = formatLabel(title, search);
  }

  return (
    <>
      <div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          square={true}
          disableGutters
          sx={{
            fontSize: "0.625rem",
            borderRadius: "0.75rem",
            boxShadow: "1px 2px 10px -3px gray",
            // background: "linear-gradient(to right, yellow 25%, white 25%)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              boxShadow: expanded ? "0 4px 5px -2px gray" : "",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div
                style={{
                  width: "75%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "table",
                    tableLayout: "fixed",
                  }}
                >
                  <Typography
                    sx={{
                      minWidth: 0,
                      display: "table-cell",
                      whiteSpace:
                        expanded === "panel1" || search ? null : "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {title}
                  </Typography>
                </div>
                <div>
                  <i>{categoryToStr(props.category)}</i>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "25%",
                }}
              >
                <Typography sx={{ color: "text.secondary", textAlign: "end" }}>
                  {priceFormat(props.totalPrice)}
                </Typography>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: "#F3F3F3",
              borderRadius: "0rem 0rem 0.75rem 0.75rem",
            }}
          >
            {props.menus ? (
              <>
                <Typography fontFamily={"Rubik"}>Menu</Typography>
                <div>
                  {props.menus.map((menu, i) => (
                    <AccordionDetail
                      currentIdx={i}
                      menuCount={props.menus.length}
                      menu={menu}
                      key={i}
                    />
                  ))}
                </div>
              </>
            ) : (
              <AccordionDetail
                currentIdx={0}
                menuCount={1}
                menu={noMenu}
                key={0}
              />
            )}
            {props.additionalCost ? (
              <AdditionalCost props={props.additionalCost} />
            ) : null}
            {props.paymentMethods ? (
              <PaymentMethod props={props.paymentMethods} />
            ) : null}
            {props.ourReview ? <OurReview props={props.ourReview} /> : null}
            {props.detailInformation ? (
              <AnotherInformation props={props.detailInformation} />
            ) : null}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};
