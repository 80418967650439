import "./App.css";
import { Container, ThemeProvider, createTheme } from "@mui/material";

import { Header } from "./components/Header";
import { Index } from "./pages/Index";

const theme = createTheme({
  typography: { allVariants: { fontFamily: "Nunito", fontWeight: "bold" } },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div style={{ marginBottom: "0%" }}>
        <Header />
        <Container>
          <Index />
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default App;
