export const priceFormat = (price, isAddOns = false) => {
  if (price === 0 && !isAddOns) {
    return "Gratis!";
  }

  if (price === 0 && isAddOns) {
    return "";
  }

  if (price / 1) {
    return round(price / 1000, 1) + "rb";
  }

  return "";
};

const round = (value, precision) => {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const formatPaymentMethod = (paymentMethods) => {
  if (paymentMethods.length === 0) {
    return [];
  }

  let result = [];

  if (paymentMethods.includes("cash")) {
    result.push("Tunai");
  }

  if (paymentMethods.includes("qr")) {
    result.push("QRIS");
  }

  if (paymentMethods.includes("debit")) {
    result.push("Debit");
  }

  return result;
};

export const getRandomWholeNumber = (max) => {
  return Math.floor(Math.random() * max);
};

export const categoryToStr = (categories) => {
  if (categories.length === 0) {
    return [];
  }

  let sortedCategories = [];

  if (categories.includes("ayce")) {
    sortedCategories.push("All You Can Eat");
  }

  if (categories.includes("vegan")) {
    sortedCategories.push("Vegan");
  }

  if (categories.includes("eatery")) {
    sortedCategories.push("Eatery");
  }

  if (categories.includes("cafe")) {
    sortedCategories.push("Cafe");
  }

  if (categories.includes("coffee")) {
    sortedCategories.push("Coffee Shop");
  }

  if (categories.includes("bakery")) {
    sortedCategories.push("Bakery");
  }

  if (categories.includes("snack")) {
    sortedCategories.push("Snack");
  }

  if (categories.includes("parlor")) {
    sortedCategories.push("Ice Cream");
  }

  if (categories.includes("beverage")) {
    sortedCategories.push("Beverage");
  }

  if (categories.includes("dessert")) {
    sortedCategories.push("Dessert");
  }

  return sortedCategories.join(", ");
};
