import {
  Avatar,
  Backdrop,
  Box,
  Chip,
  Container,
  Divider,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { ControlledAccordions } from "../components/Accordion";
import { restaurants } from "../assets/restaurants";
import { useState } from "react";
import {
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  AutoAwesomeOutlined,
} from "@mui/icons-material";
import { Masonry } from "@mui/lab";
import { Button } from "@mui/material";
import { getRandomWholeNumber } from "../helpers/util";
import { lastUpdated } from "../constants/constant";

export const Index = () => {
  const allCategories = [
    { label: "Eatery", value: "eatery" },
    { label: "All You Can Eat", value: "ayce" },
    { label: "Bakery", value: "bakery" },
    { label: "Beverage", value: "beverage" },
    { label: "Cafe", value: "cafe" },
    { label: "Dessert", value: "dessert" },
    { label: "Ice Cream", value: "parlor" },
    { label: "Snack", value: "snack" },
    { label: "Vegan", value: "vegan" },
    { label: "Coffee Shop", value: "coffee" },
  ];

  const sortOptions = [
    { label: "Nama", value: "title" },
    { label: "Harga", value: "totalPrice" },
    { label: "Bintang Derby", value: "ourReview.rating.derby" },
    { label: "Bintang Riska", value: "ourReview.rating.riska" },
  ];

  // filter
  const [isSelectedLt30, setIsSelectedLt30] = useState(false);
  const [isSelectedGt30Lt60, setIsSelectedGt30Lt60] = useState(false);
  const [isSelectedGt60Lt120, setIsSelectedGt60Lt120] = useState(false);
  const [isSelectedGt120, setIsSelectedGt120] = useState(false);

  // sorting
  const [sortedBy, setSortedBy] = useState("title");

  // sort order
  const [orderBy, setOrderBy] = useState("asc");

  // search
  const [search, setSearch] = useState("");

  // category
  const [selectedCategory, setSelectedCategory] = useState([]);

  // recomendation
  const [isDerbyRecommendation, setIsDerbyRecommendation] = useState(false);
  const [isRiskaRecommendation, setIsRiskaRecommendation] = useState(false);

  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOnClickLt30 = () => {
    setIsSelectedLt30(!isSelectedLt30);
    setIsSelectedGt30Lt60(false);
    setIsSelectedGt60Lt120(false);
    setIsSelectedGt120(false);
  };

  const handleOnClickGt30Lt60 = () => {
    setIsSelectedLt30(false);
    setIsSelectedGt30Lt60(!isSelectedGt30Lt60);
    setIsSelectedGt60Lt120(false);
    setIsSelectedGt120(false);
  };

  const handleOnClickGt60Lt120 = () => {
    setIsSelectedLt30(false);
    setIsSelectedGt30Lt60(false);
    setIsSelectedGt60Lt120(!isSelectedGt60Lt120);
    setIsSelectedGt120(false);
  };

  const handleOnClickGt120 = () => {
    setIsSelectedLt30(false);
    setIsSelectedGt30Lt60(false);
    setIsSelectedGt60Lt120(false);
    setIsSelectedGt120(!isSelectedGt120);
  };

  const handleOnClickSorting = (newSortedBy) => {
    if (newSortedBy === sortedBy) {
      if (orderBy === "asc") {
        setOrderBy("desc");
      } else {
        setOrderBy("asc");
      }
    } else {
      setOrderBy("asc");
    }

    setSortedBy(newSortedBy);
  };

  const handleClickCategory = (clickedCategory) => {
    if (selectedCategory.includes(clickedCategory)) {
      const newSelectedCategory = selectedCategory.filter(
        (e) => e !== clickedCategory
      );
      setSelectedCategory(newSelectedCategory);
    } else {
      setSelectedCategory([...selectedCategory, clickedCategory]);
    }
  };

  let resultRestaurant = restaurants;

  if (search) {
    resultRestaurant = resultRestaurant.filter((restaurant) =>
      restaurant.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        ? restaurant
        : null
    );
  }

  const usePriceFilter = (restaurant) => {
    if (isSelectedLt30) {
      return restaurant.totalPrice <= 30000;
    }

    if (isSelectedGt30Lt60) {
      return restaurant.totalPrice > 30000 && restaurant.totalPrice <= 60000;
    }

    if (isSelectedGt60Lt120) {
      return restaurant.totalPrice > 60000 && restaurant.totalPrice <= 120000;
    }

    if (isSelectedGt120) {
      return restaurant.totalPrice > 120000;
    }

    return restaurant;
  };

  const useRatingFilter = (restaurant) => {
    if (isDerbyRecommendation && isRiskaRecommendation) {
      return (
        (restaurant.ourReview?.rating?.derby || 0) >= 4.5 &&
        (restaurant.ourReview?.rating?.riska || 0) >= 4.5
      );
    }

    if (isDerbyRecommendation) {
      return (restaurant.ourReview?.rating?.derby || 0) >= 4.5;
    }

    if (isRiskaRecommendation) {
      return (restaurant.ourReview?.rating?.riska || 0) >= 4.5;
    }

    return restaurant;
  };

  const useCategoryFilter = (restaurant) => {
    const checkSubset = (parentArray, subsetArray) => {
      return subsetArray.every((el) => {
        return parentArray.includes(el);
      });
    };

    if (!checkSubset(restaurant.category, selectedCategory)) {
      return false;
    }

    return true;
  };

  const prop = (key) => {
    var keys = key.split(".");

    return keys.reduce.bind(keys, function (obj, name) {
      return obj[name];
    });
  };

  const useSort = () => {
    var getKey = prop(sortedBy);

    if (orderBy === "asc") {
      return (a, b) => {
        var x = getKey(a);
        var y = getKey(b);

        return x === y ? 0 : x < y ? -1 : 1;
      };
    } else {
      return (a, b) => {
        var x = getKey(a);
        var y = getKey(b);

        return x === y ? 0 : x < y ? 1 : -1;
      };
    }
  };

  resultRestaurant = resultRestaurant
    .filter(usePriceFilter)
    .filter(useRatingFilter)
    .filter(useCategoryFilter)
    .sort(useSort());

  return (
    <>
      <Container sx={{ marginBottom: "5%", marginTop: "10%", width: "345px" }}>
        <Grid container direction="column" alignContent="center">
          <Grid item marginBottom="5px">
            <Typography fontSize="12px">Harga</Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Chip
                  label={<Typography fontSize="10px">{"< 30rb"}</Typography>}
                  variant={isSelectedLt30 ? "filled" : "outlined"}
                  onClick={handleOnClickLt30}
                />
              </Grid>
              <Grid item>
                <Chip
                  label={<Typography fontSize={"10px"}>{"30-60rb"}</Typography>}
                  variant={isSelectedGt30Lt60 ? "filled" : "outlined"}
                  onClick={handleOnClickGt30Lt60}
                />
              </Grid>
              <Grid item>
                <Chip
                  label={
                    <Typography fontSize={"10px"}>{"60-120rb"}</Typography>
                  }
                  variant={isSelectedGt60Lt120 ? "filled" : "outlined"}
                  onClick={handleOnClickGt60Lt120}
                />
              </Grid>
              <Grid item>
                <Chip
                  label={<Typography fontSize={"10px"}>{"> 120rb"}</Typography>}
                  variant={isSelectedGt120 ? "filled" : "outlined"}
                  onClick={handleOnClickGt120}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item marginBottom="5px" marginTop="10px">
            <Typography fontSize="12px">Urut berdasarkan</Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={1}>
              {sortOptions.map((sort, index) => {
                return (
                  <Grid item key={index}>
                    <Chip
                      label={
                        <Typography fontSize="10px">{sort.label}</Typography>
                      }
                      variant={sortedBy === sort.value ? "filled" : "outlined"}
                      onClick={() => handleOnClickSorting(sort.value)}
                      icon={
                        sortedBy === sort.value ? (
                          orderBy === "asc" ? (
                            <ArrowDropUpRounded />
                          ) : (
                            <ArrowDropDownRounded />
                          )
                        ) : null
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item marginBottom="5px" marginTop="10px">
            <Typography fontSize="12px">Jenis</Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={1}>
              {allCategories.map((category, idx) => {
                return (
                  <Grid item key={idx}>
                    <Chip
                      label={
                        <Typography fontSize="10px">
                          <i>{category.label}</i>
                        </Typography>
                      }
                      variant={
                        selectedCategory.includes(category.value)
                          ? "filled"
                          : "outlined"
                      }
                      // sx={{
                      //   ":hover": {
                      //     boxShadow: "1px 2px 10px -3px gray",
                      //   },
                      // }}
                      onClick={() => handleClickCategory(category.value)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item marginBottom="5px" marginTop="10px">
            <Typography fontSize="12px">Rekomendasi kami</Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Chip
                  avatar={
                    <Avatar sx={{ bgcolor: "#2BCDC1" }}>
                      <Typography fontSize="10px" color="#393E46">
                        D
                      </Typography>
                    </Avatar>
                  }
                  label={<Typography fontSize="10px">Bintang 4.5+</Typography>}
                  variant={isDerbyRecommendation ? "filled" : "outlined"}
                  onClick={() =>
                    setIsDerbyRecommendation(!isDerbyRecommendation)
                  }
                />
              </Grid>
              <Grid item>
                <Chip
                  avatar={
                    <Avatar sx={{ bgcolor: "#2BCDC1" }}>
                      <Typography fontSize="12px" color="#393E46">
                        R
                      </Typography>
                    </Avatar>
                  }
                  label={<Typography fontSize="10px">Bintang 4.5+</Typography>}
                  variant={isRiskaRecommendation ? "filled" : "outlined"}
                  onClick={() =>
                    setIsRiskaRecommendation(!isRiskaRecommendation)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item marginBottom="5px" marginTop="10px">
            <Typography fontSize="12px">Cari makanan kesukaanmu</Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={1}>
              <Grid item width={"100%"}>
                <TextField
                  size="small"
                  placeholder="Bakso"
                  fullWidth
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item marginTop="20px">
            <Grid
              container
              direction="column"
              spacing={1}
              alignContent="center"
            >
              <Grid item>
                <Button
                  onClick={handleOpen}
                  size="large"
                  variant="outlined"
                  endIcon={<AutoAwesomeOutlined />}
                  sx={{
                    borderRadius: "25px",
                    color: "#EF6351",
                    borderColor: "#EF6351",
                    ":hover": {
                      color: "#FFFFFF",
                      borderColor: "#EF6351",
                      backgroundColor: "#EF6351",
                    },
                  }}
                >
                  Bingung?
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Box
        marginBottom={2}
        marginTop={5}
        display="flex"
        justifyContent="space-between"
      >
        <Typography fontWeight={"300"} fontSize={12} width="30%">
          {resultRestaurant.length > 0
            ? `${resultRestaurant.length} data`
            : "Tidak ada hasil"}
        </Typography>
        <Typography
          fontWeight={"300"}
          fontSize={12}
          width="70%"
          textAlign="end"
        >
          Pembaruan terakhir: {lastUpdated}
        </Typography>
      </Box>
      <Masonry
        spacing={2}
        columns={{ xs: 1, sm: 2, md: 3 }}
        sx={{ width: "auto" }}
      >
        {/* <Grid spacing={2} container sx={{ gridAutoFlow: "column" }}> */}
        {resultRestaurant.map((restaurant, idx) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={idx}>
              <ControlledAccordions props={restaurant} search={search} />
            </Grid>
          );
        })}
      </Masonry>

      <Divider sx={{ marginTop: "100px" }} />
      <Box marginY={"10px"} display={"flex"} justifyContent={"center"}>
        <Typography fontSize={12} color={"GrayText"}>
          © 2023 derbyps
        </Typography>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "70%", sm: "400px" },
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "10px",
            }}
          >
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              mb="10px"
              fontFamily="Rubik"
            >
              Cobain ini deh
            </Typography>
            <Grid item xs={12} sm={6} md={4}>
              <ControlledAccordions
                props={restaurants[getRandomWholeNumber(restaurants.length)]}
                search={search}
              />
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
